import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import HeroSection from "../components/Home/HeroSection";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Values from "../components/About/Values";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import PaleteaColor from "../components/global/Paletacolor" 
import ServicesBlock from '../components/Home/ServicesBlock_2'
import BlockHomeTwo from "../components/Home/BlockHomeTwo";

function Home() {
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroSection />
        <BlockHomeOne />
        <Directories />
        <BlockAboutOne />
        <div className="mt-[10px]">
          <Values />
        </div>
        <BlockHomeTwo/>
        <div>
        <h2 className="text-center">Our Color Palette</h2>
        </div>
        <PaleteaColor/>
        <ServicesBlock/>
        <div className=" mx-auto justify-center mb-10">
          <h2 className=" text-center">What Our Customers Say</h2>
          <div class="elfsight-app-ac847d0f-bbef-475f-aabc-1d206fbee6dd"></div>
        </div>
        {/* <ServicesHome /> */}
        <div className="py-[100px] pintura" >
          <FormHome />
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
