import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from "react-icons/fi";
import { MdOutlineVisibility } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";


function Values_2() {  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [   
    
{ title: "Mission",      description: rpdata?.dbValues?.[0].description,
 icon: ( <FiTarget style={{ fontSize:"70", color:"var(--secondary-color)" }} className=" border-black border-2 p-2 rounded-full"/> ),    }, 
  
  
  
{title: "Vision",  
description: rpdata?.dbValues?.[1].description, 
icon: (   <MdOutlineVisibility style={{ fontSize:"70", color:"var(--main-color)" }} className=" border-black border-2 p-2 rounded-full" />    )   },
  
{title: "Why Choose Us",
description: rpdata?.dbValues?.[2].description,
icon: ( <FaHandshake style={{ fontSize:"70", color:"var(--secondary-color)" }} className=" border-black border-2 p-2 rounded-full"/>  ),    },  ];



  return (    <>      
  <div className="md:w-2/3 w-full md:text-start text-center  md:mx-auto mt-6">  
  <h5>{rpdata?.dbPrincipal?.name}</h5>        
  <h3 className="color-2">{rpdata?.dbSlogan?.[2].slogan}</h3>      
  </div>      
    <div className="md:w-4/5 md:mx-auto w-full flex flex-col md:flex-row md:mb-0 mb-10">    
        <div className="md:w-[60%]">  {valuesData.map((items, index) => {
               return ( 
               <div  key={index} 
                className="bg-white w-4/5 mx-auto text-start flex md:flex-row flex-col my-6">    
        <div className="flex flex-col justify-center items-center md:p-4 md:text-center text-center md:w-[30%]">                  {items.icon}                  <span>{items.title}</span>                </div>                <div className="p-4 border-l-[1px] border-gray-500 md:w-[70%]">                  <p className="md:text-start">{items.description}</p>                </div>              </div>            );          })}        </div>        <div className="md:w-[40%] w-full md:h-auto h-[300px] md:flex hidden">         
  
     <div className="w-full md:h-[500px] bg-cover bg-center m-1 rounded-full "
      style={{ backgroundImage: `url("${rpdata?.stock?.[3]}")`, backgroundSize:"Cover"}}>
      </div>     
    </div>      
  </div>    
</>  );}
export default Values_2;
